import { Create, SimpleForm, TextInput, required } from 'react-admin'
import { useLocation } from 'react-router'
import { useMemo } from 'react'
import { LocaleInput } from '../../components/locale-input/locale-input'
import { EditorInput } from '../../components/editor-input/editor-input'

export const ManualContentCreate = () => {
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const manualId = query.get('manual_id')
  if (!manualId) {
    return null
  }

  return (
    <Create redirect="show">
      <SimpleForm defaultValues={{ manualId }} warnWhenUnsavedChanges>
        <TextInput source="title" validate={required()} />
        <LocaleInput source="language" isRequired />
        <TextInput source="manualId" disabled />
        <EditorInput source="content" />
      </SimpleForm>
    </Create>
  )
}
