import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin'
import { LocaleField } from '../../components/locale-field/locale-field'
import { ManualContentActions } from './manual-content-actions'
import { ManualContentTitle } from './manual-content-title'
import { EditorField } from '../../components/editor-field/editor-field'

export const ManualContentShow = () => {
  return (
    <Show actions={<ManualContentActions />} title={<ManualContentTitle />}>
      <SimpleShowLayout>
        <TextField source="title" />
        <LocaleField source="language" />
        <DateField source="lastEditedAt" showTime />
        <EditorField source="content" />
      </SimpleShowLayout>
    </Show>
  )
}
