import {
  Datagrid,
  DateField,
  Edit,
  EditButton,
  LoadingInput,
  ReferenceManyField,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  required,
  useGetManyReference,
  useRecordContext
} from 'react-admin'
import { DeleteWithConfirmButtonCustom } from '../../components/delete-with-confirm-button-custom/delete-with-confirm-button-custom'
import { WrapperListActions } from '../../components/wrapper-list-actions/wrapper-list-actions'
import { AddManualContentButton } from './add-manual-content-button'
import { ManualTitle } from './manual-title'
import { ToolbarEdit } from '../../components/toolbar-edit/toolbar-edit'
import { DatagridBulkActionButtonsCustom } from '../../components/datagrid-bulk-action-buttons-custom/datagrid-bulk-actions-buttons-custom'
import { LocaleInput } from '../../components/locale-input/locale-input'

const DefaultLanguageInput = () => {
  const record = useRecordContext()
  const { data, isError } = useGetManyReference('manual-content', {
    target: 'manualId',
    id: record?.id
  })

  if (!data) {
    return <LoadingInput fullWidth />
  }

  if (isError) {
    return <p>Something went wrong, please try again later</p>
  }

  return (
    <LocaleInput
      source="defaultLanguage"
      availableLocale={data.map((item) => item.language)}
    />
  )
}

export const ManualEdit = () => {
  return (
    <Edit redirect="show" title={<ManualTitle />}>
      <SimpleForm toolbar={<ToolbarEdit />} warnWhenUnsavedChanges>
        <TextInput source="name" validate={required()} />
        <DefaultLanguageInput />
        <ReferenceManyField
          label="Language mutations"
          reference="manual-content"
          target="manualId"
        >
          <Datagrid
            bulkActionButtons={<DatagridBulkActionButtonsCustom />}
            sx={{
              '&': {
                width: '100%',
                margin: '0 0 8px 0'
              }
            }}
          >
            <TextField source="title" sortable={false} />
            <TextField source="language" sortable={false} />
            <DateField source="lastEditedAt" sortable={false} />
            <WrapperListActions>
              <ShowButton />
              <EditButton />
              <DeleteWithConfirmButtonCustom />
            </WrapperListActions>
          </Datagrid>
        </ReferenceManyField>
        <AddManualContentButton />
      </SimpleForm>
    </Edit>
  )
}
