import React from 'react'
import { useRecordContext } from 'react-admin'

export const ManualContentTitle = () => {
  const record = useRecordContext()
  const title = record ? (record.title as string) : ''
  const language = record ? (record.language as string) : ''

  return (
    <span>Manual content{title && language && `: ${language} - ${title}`}</span>
  )
}
