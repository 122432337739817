import React from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { AppBar, Layout } from 'react-admin'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: white;
`

const PRODUCTION_READER_APP_URL = 'https://m.man.plus'
const MANUAL_HOW_TO_USE_MAN_PLUS_LOCATION = '/m/A01'
const redirectToManualUrl =
  PRODUCTION_READER_APP_URL + MANUAL_HOW_TO_USE_MAN_PLUS_LOCATION

const CustomAppBar = (props: any) => (
  <AppBar>
    <Wrapper>
      <Typography variant="h6" color="inherit" id="react-admin-title" />
      <IconButton
        color="inherit"
        aria-label="upload picture"
        component="label"
        onClick={() => {
          window.open(redirectToManualUrl, '_blank')
        }}
      >
        <QuestionMarkIcon />
      </IconButton>
    </Wrapper>
  </AppBar>
)

export const CustomLayout = (props: any) => (
  <Layout {...props} appBar={CustomAppBar} />
)
