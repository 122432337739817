import React from 'react'
import { Admin, Resource } from 'react-admin'
import simpleRestProvider from 'ra-data-simple-rest'
import { ManualList } from './resources/manual/manual-list'
import { ManualShow } from './resources/manual/manual-show'
import { ManualCreate } from './resources/manual/manual-create'
import { ManualEdit } from './resources/manual/manual-edit'
import { authProvider } from './utils/auth-provider/auth-provider'
import { httpClient } from './utils/http-client/http-client'
import { ManualContentShow } from './resources/manual-content/manual-content-show'
import { ManualContentCreate } from './resources/manual-content/manual-content-create'
import { ManualContent } from './resources/manual-content/manual-content.types'
import { ManualContentEdit } from './resources/manual-content/manual-content-edit'
import { CustomLayout } from './components/custom-layout/custom-layout'
import { CookiesAlert } from './components/cookies-alert/cookies-alert'

const backendAddress =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BACKEND_URL ?? ''
    : 'http://localhost:8000'

const dataProvider = simpleRestProvider(backendAddress, httpClient)

const App = () => (
  <>
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      layout={CustomLayout}
    >
      <Resource
        name="manual"
        list={ManualList}
        show={ManualShow}
        edit={ManualEdit}
        create={ManualCreate}
      />
      <Resource
        name="manual-content"
        show={ManualContentShow}
        create={ManualContentCreate}
        edit={ManualContentEdit}
        recordRepresentation={(record: ManualContent) =>
          `${record.language} - ${record.title}`
        }
      />
    </Admin>

    <CookiesAlert />
  </>
)

export default App
