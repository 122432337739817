import React from 'react'
import { useRecordContext, useRedirect } from 'react-admin'
import Button from '@mui/material/Button'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

export const BackToManualButton = () => {
  const redirect = useRedirect()
  const record = useRecordContext()

  const redirectLocation = record?.manualId
    ? `manual/${record.manualId as string}/show`
    : 'manuals'

  return (
    <Button
      onClick={() => redirect(() => redirectLocation)}
      startIcon={<ArrowBackIosNewIcon />}
    >
      Back to manual
    </Button>
  )
}
