import { EditButton, ShowButton, TopToolbar } from 'react-admin'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { BackToManualButton } from './back-to-manual-button'
import { DeleteWithConfirmButtonCustom } from '../../components/delete-with-confirm-button-custom/delete-with-confirm-button-custom'

const WrapperAll = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const WrapperMutativeActions = styled.div`
  display: flex;
  column-gap: 10px;
`

export const ManualContentActions = () => {
  const location = useLocation()

  const shouldShowShowButton = !location.pathname.includes('show')
  const shouldShowEditButton = !shouldShowShowButton

  return (
    <TopToolbar>
      <WrapperAll>
        <BackToManualButton />
        <WrapperMutativeActions>
          {shouldShowShowButton && <ShowButton />}
          {shouldShowEditButton && <EditButton />}
          <DeleteWithConfirmButtonCustom />
        </WrapperMutativeActions>
      </WrapperAll>
    </TopToolbar>
  )
}
