import {
  Datagrid,
  DateField,
  EditButton,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  TopToolbar
} from 'react-admin'
import { DeleteWithConfirmButtonCustom } from '../../components/delete-with-confirm-button-custom/delete-with-confirm-button-custom'
import ManualUrlField from '../../components/manual-url-field/manual-url-field'
import { WrapperListActions } from '../../components/wrapper-list-actions/wrapper-list-actions'
import { AddManualContentButton } from './add-manual-content-button'
import { ManualTitle } from './manual-title'
import { DatagridBulkActionButtonsCustom } from '../../components/datagrid-bulk-action-buttons-custom/datagrid-bulk-actions-buttons-custom'
import { LocaleField } from '../../components/locale-field/locale-field'

const Actions = () => {
  return (
    <TopToolbar>
      <EditButton />
      <DeleteWithConfirmButtonCustom />
    </TopToolbar>
  )
}

export const ManualShow = () => {
  return (
    <Show actions={<Actions />} title={<ManualTitle />}>
      <SimpleShowLayout>
        <TextField source="name" />
        <LocaleField source="defaultLanguage" />
        <ReferenceManyField
          label="Language mutations"
          reference="manual-content"
          target="manualId"
        >
          <Datagrid bulkActionButtons={<DatagridBulkActionButtonsCustom />}>
            <TextField source="title" sortable={false} />
            <TextField source="language" sortable={false} />
            <DateField source="lastEditedAt" sortable={false} />
            <WrapperListActions>
              <ShowButton />
              <EditButton />
              <DeleteWithConfirmButtonCustom />
            </WrapperListActions>
          </Datagrid>
        </ReferenceManyField>
        <AddManualContentButton />
        <ManualUrlField />
      </SimpleShowLayout>
    </Show>
  )
}
