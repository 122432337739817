import { Create, SimpleForm, TextInput, required } from 'react-admin'

export const ManualCreate = () => {
  return (
    <Create redirect="show">
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput source="name" validate={required()} />
      </SimpleForm>
    </Create>
  )
}
