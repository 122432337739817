import React from 'react'
import { DeleteWithConfirmButton, useRecordContext } from 'react-admin'

export const DeleteWithConfirmButtonCustom = () => {
  const record = useRecordContext()

  if (!record) return null

  const label = record
    ? ((record.name ?? record.title ?? record.id) as string)
    : ''

  return <DeleteWithConfirmButton confirmTitle={'Delete: ' + label} />
}
