import React from 'react'
import { required, useInput } from 'react-admin'
import styled from 'styled-components'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'

const TIME_TRAVEL_SECTION = ['undo', 'redo']
const TEXT_SECTION = [
  'formatBlock',
  'bold',
  'underline',
  'italic',
  'strike',
  'fontColor',
  'hiliteColor',
  'removeFormat',
  'blockquote'
]
const ALIGNMENT_SECTION = [
  'outdent',
  'indent',
  'align',
  'list',
  'horizontalRule'
]
const MEDIA_SECTION = ['table', 'image', 'video', 'audio', 'link', 'showBlocks']

const buttonList = [
  TIME_TRAVEL_SECTION,
  TEXT_SECTION,
  ALIGNMENT_SECTION,
  MEDIA_SECTION
]

const DISABLE_MEDIA_ALIGNMENT_OPTIONS = {
  imageAlignShow: false,
  videoAlignShow: false
}

const RedLine = styled.div`
  width: 100%;
  border-bottom: 1px solid red;
`

export const EditorInput = ({ source }: { source: string }) => {
  const { field, fieldState } = useInput({
    source,
    validate: required()
  })

  return (
    <>
      <SunEditor
        height="50vh"
        onChange={field.onChange}
        setContents={field.value}
        setOptions={{ buttonList, ...DISABLE_MEDIA_ALIGNMENT_OPTIONS }}
      />

      {fieldState.error && (
        <>
          <RedLine />
          <p style={{ color: 'red', marginLeft: '14px', fontSize: '0.75rem' }}>
            Required
          </p>
        </>
      )}
    </>
  )
}
