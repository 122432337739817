import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRecordContext } from 'react-admin'
import QRCode from 'qrcode'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import { Manual } from '../../resources/manual/manual.types'

const WrapperInner = styled.div`
  display: flex;
`

const WrapperQrCode = styled.div`
  margin-right: 10px;
`

const URL_ROOT = 'https://m.man.plus/m'

const ManualUrlField = () => {
  const record = useRecordContext<Manual>()
  const [qrCodeBase64, setQrCodeBase64] = useState<string>()

  const url = useMemo(() => {
    return `${URL_ROOT}/${record.id}`
  }, [record.id])

  useEffect(() => {
    void QRCode.toDataURL(url, { margin: 1 }).then((dataUrl) => {
      setQrCodeBase64(dataUrl)
    })
  }, [url])

  const onDownload = useCallback(() => {
    if (!qrCodeBase64) return

    const link = document.createElement('a')
    link.download = `manual-${record.name ?? record.id}-qr-code.png`
    link.href = qrCodeBase64
    link.click()
  }, [qrCodeBase64])

  return (
    <WrapperInner>
      <WrapperQrCode>
        {qrCodeBase64 && <img src={qrCodeBase64} alt="Link to manual" />}
      </WrapperQrCode>

      <div>
        <Button onClick={onDownload} variant="contained">
          Download QR code as image
        </Button>
        <p>
          <a href={url}>{url}</a>
        </p>
      </div>
    </WrapperInner>
  )
}

ManualUrlField.defaultProps = {
  label: 'Public link and QR code',
  addLabel: true
}

export default ManualUrlField
