import { signInWithEmailAndPassword, User } from 'firebase/auth'
import { AuthProvider, UserIdentity } from 'react-admin'
import { auth } from '../firebase/firebase'

const getUserIdentityFromUser = (user: User): UserIdentity => ({
  id: user.uid,
  fullName: user.displayName ?? undefined
})

export const authProvider: AuthProvider = {
  login: async (params) => {
    const user = await signInWithEmailAndPassword(
      auth,
      params.username,
      params.password
    )

    return !!user
  },
  checkError: async (error) => {
    const status = error.status

    // @DEBT: Horrible solution, I didn't have time to create custom data-provider show nice error messages to users.
    // This is a minimalistic solution, so that user at least knows what's happening
    if (status === 409) {
      alert(
        'Manual with this langauge already exists, please edit or delete the existing one.'
      )
    }

    if (status === 401 || status === 403) {
      return await Promise.reject(new Error('Server returned auth error'))
    }

    return await Promise.resolve()
  },
  checkAuth: async (params) => {
    if (!auth.currentUser) {
      throw new Error('Not authenticated, please login.')
    }

    return await Promise.resolve()
  },
  logout: async () => {
    return await auth.signOut()
  },
  getIdentity: async () => {
    const user = auth.currentUser
      ? getUserIdentityFromUser(auth.currentUser)
      : undefined

    if (!user) {
      throw new Error('No user info')
    }

    return await Promise.resolve(user)
  },
  getPermissions: async () => {
    return await Promise.resolve()
  }
}
