import { useState } from 'react'
import { Edit, SimpleForm, TextInput, required } from 'react-admin'
import { EditorInput } from '../../components/editor-input/editor-input'
import { LocaleInput } from '../../components/locale-input/locale-input'
import { ManualContentActions } from './manual-content-actions'
import { ManualContentTitle } from './manual-content-title'
import { ToolbarEdit } from '../../components/toolbar-edit/toolbar-edit'

export const ManualContentEdit = () => {
  // We can set initial value to the editor only once, thus we disable editor until the complete `ManualContent` is loaded
  // (ManualContentList only loads `manualContentRecords` not `manualContents)
  const [isEditorEnabled, setIsEditorEnabled] = useState(false)

  return (
    <Edit
      queryOptions={{
        onSuccess: () => {
          setIsEditorEnabled(true)
        }
      }}
      redirect={'show'}
      actions={<ManualContentActions />}
      title={<ManualContentTitle />}
    >
      <SimpleForm toolbar={<ToolbarEdit />} warnWhenUnsavedChanges>
        <TextInput source="title" validate={required()} />
        <LocaleInput source="language" disabled />
        {isEditorEnabled && <EditorInput source="content" />}
      </SimpleForm>
    </Edit>
  )
}
