import React from 'react'
import { Link } from 'react-router-dom'
import { Button, useRecordContext } from 'react-admin'
import { Manual } from './manual.types'
import CreateIcon from '@mui/icons-material/Create'

export const AddManualContentButton = () => {
  const record = useRecordContext<Manual>()

  return (
    <Button
      variant="contained"
      component={Link}
      to={`/manual-content/create?manual_id=${record.id}`}
      label="Add a translation"
      title="Add a translation"
      color="primary"
      startIcon={<CreateIcon />}
    />
  )
}
