import React from 'react'
import { AutocompleteInput, required } from 'react-admin'
import { getLocaleChoices } from '../../utils/get-locale-choices/get-locale-choices'

export const LocaleInput = ({
  source,
  isRequired,
  disabled,
  availableLocale
}: {
  source: string
  isRequired?: boolean
  disabled?: boolean
  availableLocale?: string[]
}) => {
  const choices = getLocaleChoices(availableLocale)

  return (
    <AutocompleteInput
      source={source}
      choices={choices}
      fullWidth
      validate={isRequired ? required() : undefined}
      disabled={disabled}
    />
  )
}
