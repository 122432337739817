import localeCodes from 'locale-codes'
import localeEmoji from 'locale-emoji'

export const getLocaleChoices = (availableLocale?: string[]) => {
  if (availableLocale && availableLocale.length > 0) {
    return getLocaleChoicesForAvailableLocale(availableLocale)
  }

  return getAllLocaleChoices()
}

const getAllLocaleChoices = () =>
  localeCodes.all.map((locale) => {
    const emoji = localeEmoji(locale.tag)
    return { id: locale.tag, name: `${emoji} ${locale.name} (${locale.tag})` }
  })

const getLocaleChoicesForAvailableLocale = (availableLocale: string[]) => {
  return availableLocale.map((availableLocaleTag) => {
    const localeFull = localeCodes.where('tag', availableLocaleTag)

    const emoji = localeEmoji(localeFull.tag)
    return {
      id: localeFull.tag,
      name: `${emoji} ${localeFull.name} (${localeFull.tag})`
    }
  })
}
