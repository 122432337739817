import React from 'react'
import { useRecordContext } from 'react-admin'
import SunEditor from 'suneditor-react'

export const EditorField = ({ source }: { source: string }) => {
  const record = useRecordContext()

  if (!record) return null

  const value = record[source]

  if (!value) return null

  return (
    <SunEditor
      disable
      height="70vh"
      setContents={value}
      setOptions={{ buttonList: [] }}
    />
  )
}
