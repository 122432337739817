import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from '@mui/material/Button'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5.6px 11.2px 11.2px hsl(0deg 0% 0% / 0.33);
`

const WrapperPosition = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 40px;
  width: 100%;
  left: 0;
  z-index: 9;
`

const COOKIES_ACCEPTED_LS_LABEL = 'cookies-accepted'

export const CookiesAlert = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const userAlreadyAcceptedCookies = localStorage.getItem(
      COOKIES_ACCEPTED_LS_LABEL
    )

    if (!userAlreadyAcceptedCookies) {
      setIsVisible(true)
    }
  }, [])

  const handleAcceptCookies = useCallback(() => {
    localStorage.setItem(COOKIES_ACCEPTED_LS_LABEL, 'true')

    setIsVisible(false)
  }, [])

  if (!isVisible) {
    return null
  }

  return (
    <WrapperPosition>
      <Wrapper>
        This app uses cookies for security purposes. By using this app you
        aggree to the use of cookies.
        <Button
          onClick={handleAcceptCookies}
          sx={{ marginLeft: '10px', marginRight: '-10px' }}
        >
          Hide message
        </Button>
      </Wrapper>
    </WrapperPosition>
  )
}
