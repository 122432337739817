import React from 'react'
import {
  CreateButton,
  Datagrid,
  EditButton,
  List,
  ShowButton,
  TextField,
  TopToolbar
} from 'react-admin'
import { DeleteWithConfirmButtonCustom } from '../../components/delete-with-confirm-button-custom/delete-with-confirm-button-custom'
import { WrapperListActions } from '../../components/wrapper-list-actions/wrapper-list-actions'
import { DatagridBulkActionButtonsCustom } from '../../components/datagrid-bulk-action-buttons-custom/datagrid-bulk-actions-buttons-custom'

const Actions = () => {
  return (
    <TopToolbar>
      <CreateButton />
    </TopToolbar>
  )
}

export const ManualList = () => {
  return (
    <List actions={<Actions />}>
      <Datagrid bulkActionButtons={<DatagridBulkActionButtonsCustom />}>
        <TextField source="name" sortable={false} />
        <WrapperListActions>
          <ShowButton />
          <EditButton />
          <DeleteWithConfirmButtonCustom />
        </WrapperListActions>
      </Datagrid>
    </List>
  )
}
