import { fetchUtils } from 'react-admin'
import { auth } from '../firebase/firebase'

const DEFAULT_EMPTY_TOKEN_WILL_FAIL = ''

export const httpClient = async (
  url: string,
  options: fetchUtils.Options = {}
) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }

  const token = await auth.currentUser?.getIdToken()

  options.headers = new Headers({
    ...options.headers,
    Authorization: `Bearer ${token ?? DEFAULT_EMPTY_TOKEN_WILL_FAIL}`
  })
  return await fetchUtils.fetchJson(url, options)
}
