import React from 'react'
import { SaveButton, Toolbar } from 'react-admin'
import styled from 'styled-components'
import { DeleteWithConfirmButtonCustom } from '../delete-with-confirm-button-custom/delete-with-confirm-button-custom'

const ToolbatStyled = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`

export const ToolbarEdit = () => {
  return (
    <ToolbatStyled>
      <SaveButton />
      <DeleteWithConfirmButtonCustom />
    </ToolbatStyled>
  )
}
